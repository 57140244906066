// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-terms-js": () => import("./../../../src/pages/booking-terms.js" /* webpackChunkName: "component---src-pages-booking-terms-js" */),
  "component---src-pages-browlamination-js": () => import("./../../../src/pages/browlamination.js" /* webpackChunkName: "component---src-pages-browlamination-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lashochbrowlift-js": () => import("./../../../src/pages/lashochbrowlift.js" /* webpackChunkName: "component---src-pages-lashochbrowlift-js" */),
  "component---src-pages-nyheter-js": () => import("./../../../src/pages/nyheter.js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */)
}

